import _ from "lodash";
import { base_remote } from "./ModelsStore";

import starting_aud from "../assets/audio/mad_race_intro.wav";
import racing_aud from "../assets/audio/mad_race_main.wav";
import podium_aud from "../assets/audio/mad_race_podium.mp3";

const starting = new Audio(starting_aud);

const racing_aud_src = `${base_remote}/lava-track/lava_race_intro.wav`;
const racing = new Audio(racing_aud_src);
racing.volume = 0.5;

const podium_aud_src = `${base_remote}/lava-track/lava_podium.wav`;
const podium = new Audio(podium_aud_src);
podium.volume = 0.1;

const racing_slow = new Audio(racing_aud);
racing_slow.playbackRate = 0.8;

export const auds = {
  starting,
  racing,
  podium,
  racing_slow,
};

export const stop_all_audio = () => {
  _.map(auds, (aud) => {
    aud.pause();
    aud.currentTime = 0;
  });
};

export const start_audio = async (id, loop = false) => {
  console.log("start", id);
  try {
    stop_all_audio();
    let aud = auds[id];
    if (aud) {
      aud.loop = loop;
      try {
        await aud.play();
      } catch (err) {
        console.log("Failed to start audio:", err);
      }
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};
export const stop_audio = (id) => {
  try {
    let aud = auds[id];
    if (aud) {
      aud.pause();
      aud.currentTime = 0;
      return true;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const pause_audio = (id) => {
  let aud = auds[id];
  if (aud) {
    aud.pause();
  }
};

const get_auds = () => {
  return auds;
};

export const mute_audio = () => {
  let auds = get_auds();
  for (let id of _.keys(auds)) {
    let aud = auds[id];
    if (aud) {
      aud.muted = true;
    }
  }
};

export const unmute_audio = () => {
  let auds = get_auds();
  for (let id of _.keys(auds)) {
    let aud = auds[id];
    if (aud) {
      aud.muted = false;
    }
  }
};
