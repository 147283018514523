import { Environment } from "@react-three/drei";
import { base_remote } from "./ModelsStore";

const environment_file = `${base_remote}/lava-track/environment_lava.hdr`;

function StarsEnvironment({ ...props }) {
  return <Environment background files={environment_file} />;
}

export default StarsEnvironment;
